.headerDiv {
  height: 60px;
  width: 100%;
  background: #002fa7;
  position: fixed;
    top: 0;
    z-index: 99;
}

.headerDiv2 {
  width: 1200px;
  margin: 0 auto;
  display: flex;

  justify-content: space-between;
}
.logo {
  width: 134px;
  height: 60px;
  cursor: pointer;
  background: url('//static.taocheche.com/taoche/pc/assets/images/logo.png') no-repeat center/100%
    32px;
}
.menu {
  width: 500px;
  display: flex;
  justify-content: flex-end;
}

.download {
  width: 98px;
  height: 36px;

  margin: 12px 0 0 20px;
  cursor: pointer;

  font-family: PingFangSC-Semibold;
  font-size: 16px;
  color: #002fa7;
  letter-spacing: 0;
  font-weight: 600;
  line-height: 36px;
  background: #ffe900;
  text-align: center;
}
