.menunav {
	height: 100%;

	> ul {
		display: table;
		list-style: none;
		height: 100%;
		padding: 0;
		margin: 0;

		> li {
			display: table-cell;
			vertical-align: middle;

			font-family: PingFangSC-Regular;
			font-size: 16px;
			color: #f2f5fa;
			letter-spacing: 0;
			font-weight: 400;

			padding: 0 20px;
			height: 100%;
			position: relative;
			cursor: pointer;

			&:after {
				content: '';

				position: absolute;
				right: 20px;
				bottom: 0;
				left: 20px;
				border-bottom: 2px solid transparent;
				transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
			}
		}

		> li:hover, .menuselected {
			font-family: PingFangSC-Semibold;
			font-weight: 600;

			&:after {
				border-bottom: 4px solid #ffe900;
			}
      
		}
	}
}

.jiantou {
	display: inherit;
	position: absolute;
	top: 47%;
	right: -1px;
	width: 11px;
	color: white;
	transform: translateY(-50%);
	transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	box-sizing: border-box;

	&:before {
		transform: rotate(223deg) translateY(-2.5px);
		position: absolute;
		width: 6px;
		height: 1.5px;
		background-color: currentcolor;
		border-radius: 2px;
		transition:  
			transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
			top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
		content: '';
	}
	&:after {
		transform: rotate(-45deg) translateY(2.5px);
		position: absolute;
		width: 6px;
		height: 1.5px;
		background-color: currentcolor;
		border-radius: 2px;
		transition: 
			transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
			top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
		content: '';
	}
}

.nav-childDiv {
	position: absolute;
	top: -160px;
	transition: opacity 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
		0 9px 28px 8px rgba(0, 0, 0, 0.05);

	border-radius: 2px;
	opacity: 0;

	font-family: PingFangSC-Regular;
	font-size: 16px;
	color: #1e293b;
	letter-spacing: 0;
	font-weight: 400;

	ul {
		padding: 15px 30px;
		display: inline-block;
		white-space: nowrap;

		li {
			display: block;
			line-height: 40px;
		}
	}
}
.navmenu1:hover .nav-childDiv {
	background: white;
	opacity: 1;
	top: 60px;
}
.childselected,
.navmenu2:hover {
	color: #002fa7;
	font-weight: 600;
}
