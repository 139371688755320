// @import "antd/dist/antd.css";

html,
body,
#app {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  position: relative;
}
div {
  box-sizing: border-box;
}
a {
  text-decoration: none;
}

input {
  border:0;
  width: 100%;
  height:40px;
  padding:4px 11px; 
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 14px;
  line-height: 1.5715;
  transition: all 0.3s;
}

input:focus{
  outline: 1px solid #40a9ff !important; 
  box-shadow:0 0 0 2px rgba(24, 144, 255, 0.2);

}

body {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  font-feature-settings: 'tnum';
}

.preAnimate{
  opacity: 0;
}
.animated{
  animation-name: showUp;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes showUp{
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.imgAnimate{
  transition: all ease 0.5s;
}
.imgAnimate:hover{
  transform: scale(1.03);
}